.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-mg {
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 180px;
  border: 1px solid rgb(151, 75, 75);
  margin: 20px;
  justify-content: center;
  border-radius: 4px;
}

img {
  width: 80px;
  height: 80px;
  margin-left: 30px;
  margin-top: 10px;
}

.link {
  text-decoration: none;
  font-size: 15px;
  position: absolute;
  top: 160px;
}

.link_maca {
  text-decoration: none;
  font-size: 15px;
  position: absolute;
  top: 390px;
}
.link_morango {
  text-decoration: none;
  font-size: 15px;
  position: absolute;

  top: 610px;
}

a {
  color: wheat;
  text-decoration: none;
}
